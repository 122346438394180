import { VAlert } from 'vuetify/lib/components/VAlert';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VContainer,
    { staticClass: "full-width-alert flex-0", attrs: { fluid: "" } },
    [
      _c(
        VAlert,
        {
          staticClass: "alert-container",
          attrs: { value: true, color: "#F6DACA" }
        },
        [
          _c("div", { staticClass: "alert-child-container" }, [
            _c(
              "div",
              [
                _c(VImg, {
                  attrs: {
                    src: require("@/assets/notification-important.svg"),
                    height: "24px",
                    width: "24px"
                  }
                })
              ],
              1
            ),
            _c("div", [
              _c("p", { staticClass: "alert-title-text" }, [
                _vm._v(
                  "\n          Evolve your operations and improve guest experience with CentricOS\n        "
                )
              ]),
              _c("p", { staticClass: "alert-sub-title-text" }, [
                _vm._v(
                  "\n          Save hours every week, increase revenue and profit, plus reduce support tickets. US sites\n          "
                ),
                _c(
                  "a",
                  {
                    staticClass: "alert-link",
                    attrs: {
                      href:
                        "https://cdn.brandfolder.io/JO5C5J0F/at/nf7vvg2s93pj7hqrxbczr8bf/AP3_to_Centric_-_B_I_US.pdf",
                      target: "_blank"
                    }
                  },
                  [_vm._v("click")]
                ),
                _vm._v("\n          this link, Canada sites "),
                _c(
                  "a",
                  {
                    staticClass: "alert-link",
                    attrs: {
                      href:
                        "https://cdn.brandfolder.io/JO5C5J0F/at/2knvqtknspn8n458qfgn3wjf/Centric_OS_in_Canada_-_AP3_to_Centric_Migration_Overview.pdf",
                      target: "_blank"
                    }
                  },
                  [_vm._v("click")]
                ),
                _vm._v(
                  " this link to learn about\n          switching from Admin Panel to CentricOS.\n        "
                )
              ])
            ])
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }