import { VApp } from 'vuetify/lib/components/VApp';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VContent } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VApp,
    {
      class: _vm.$vuetify.breakpoint.smAndDown && "overflow-x-hidden",
      attrs: { id: "operator" }
    },
    [
      !_vm.requireAuth
        ? _c("navigation", {
            attrs: { mobileDrawer: _vm.mobileDrawer },
            on: {
              drawerStatus: function($event) {
                _vm.mobileDrawer = $event
              }
            }
          })
        : _vm._e(),
      !_vm.requireAuth
        ? _c("toolbar", {
            on: {
              mobileMenuToggle: function($event) {
                _vm.mobileDrawer = !_vm.mobileDrawer
              }
            }
          })
        : _vm._e(),
      _vm.showImportantAlert ? _c("alertBanner") : _vm._e(),
      !_vm.requireAuth ? _c("notifications") : _vm._e(),
      _c(
        VContent,
        { class: _vm.contentclass, style: _vm.contentStyle },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.loading,
                  expression: "loading"
                }
              ],
              staticStyle: { "margin-top": "12px" }
            },
            [
              _c(
                VContainer,
                { attrs: { "grid-list-xl": "" } },
                [
                  _c(
                    VLayout,
                    { attrs: { row: "", wrap: "" } },
                    [
                      _c(
                        VFlex,
                        { attrs: { xs12: "" } },
                        [_c("view-title")],
                        1
                      ),
                      _c(
                        VLayout,
                        {
                          staticStyle: { height: "45vh" },
                          attrs: {
                            column: "",
                            "justify-center": "",
                            "fill-height": "",
                            "align-center": ""
                          }
                        },
                        [
                          _c(VProgressCircular, {
                            attrs: {
                              size: 90,
                              width: 9,
                              color: "primary",
                              indeterminate: ""
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("router-view", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.loading,
                expression: "!loading"
              }
            ]
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }