import Vue from 'vue';

import Router from 'vue-router';
import semverCompare from 'semver-compare';
import store from '@/store';
import { UserRoles } from '@/constants';
import Dashboard from './views/dashboard';
import splitio from './store/modules/splitio';

Vue.use(Router);

const router = new Router({
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: '/',
      name: 'dashboard',
      component: Dashboard,
    },
    {
      path: '/login',
      component: () => import(/* webpackchunkname: "login" */ './views/login'),
      children: [
        {
          path: '',
          name: 'login',
          component: () => import(/* webpackchunkname: "login" */ './views/login/login'),
          meta: {
            noAuth: true,
          },
          beforeEnter: (to, from, next) => {
            if (store.state.adminPanel.isLoggedIn) {
              next('/');
            } else {
              next();
            }
          },
        },
        {
          path: 'forgot_password',
          name: 'forgot-password',
          component: () => import(/* webpackchunkname: "login" */ './views/login/passwordReset'),
          meta: {
            noAuth: true,
          },
        },
        {
          path: 'welcome',
          name: 'welcome',
          component: () => import(/* webpackchunkname: "login" */ './views/login/welcome'),
          meta: {
            noAuth: true,
          },
        },
        {
          path: 'nextSteps',
          name: 'nextSteps',
          component: () => import(/* webpackchunkname: "login" */ './views/login/bolterNextSteps'),
          meta: {
            noAuth: true,
          },
        },
      ],
    },
    {
      path: '/sites',
      component: () => import(/* webpackchunkname: "site" */ './views/sites'),
      children: [
        {
          path: ':app?',
          name: 'sites-listing',
          component: () => import(/* webpackchunkname: "site" */ './views/sites/listing'),
        },
        {
          path: ':app/site_new',
          name: 'site-new',
          component: () => import(/* webpackchunkname: "site" */ './views/sites/site/settings'),
        },
        {
          path: ':app/site/:site_id',
          component: () => import(/* webpackchunkname: "site" */ './views/sites/site'),
          children: [
            {
              path: '',
              name: 'site-info',
              component: () => import(/* webpackchunkname: "site" */ './views/sites/site/info'),
            },
            {
              path: 'settings',
              name: 'site-settings',
              component: () => import(/* webpackchunkname: "site" */ './views/sites/site/settings'),
            },
            {
              path: 'location_settings/:location_id?',
              name: 'location-settings',
              component: () =>
                import(/* webpackchunkname: "site" */ './views/sites/site/locationEditor'),
            },
            {
              path: 'marketplace_settings',
              name: 'marketplace-settings',
              component: () => import(/* webpackchunkname: "site" */ './views/sites/marketplace'),
            },
            {
              path: 'brand_new',
              name: 'brand-new',
              component: () =>
                import(/* webpackchunkname: "site" */ './views/sites/brand/settings'),
            },
            {
              path: 'brand/:brand_id',
              component: () => import(/* webpackchunkname: "site" */ './views/sites/brand'),
              children: [
                {
                  path: '',
                  name: 'brand-overview',
                  component: () =>
                    import(/* webpackchunkname: "site" */ './views/sites/brand/overview'),
                  children: [
                    {
                      path: 'schedules',
                      name: 'brand-overview-menus',
                      props: true,
                      component: () =>
                        import(/* webpackchunkname: "site" */ './views/sites/brand/overview/menus'),
                    },
                    {
                      path: 'pickup',
                      name: 'brand-overview-pickup',
                      component: () =>
                        import(
                          /* webpackchunkname: "site" */ './views/sites/brand/overview/operationHours'
                        ),
                    },
                    {
                      path: 'delivery',
                      name: 'brand-overview-delivery',
                      component: () =>
                        import(
                          /* webpackchunkname: "site" */ './views/sites/brand/overview/deliveryHours'
                        ),
                    },
                    {
                      path: 'closure',
                      name: 'brand-overview-closure',
                      component: () =>
                        import(
                          /* webpackchunkname: "site" */ './views/sites/brand/overview/closure'
                        ),
                    },
                  ],
                },
                {
                  path: 'settings',
                  name: 'brand-settings',
                  component: () =>
                    import(/* webpackchunkname: "site" */ './views/sites/brand/settings'),
                },
                {
                  path: 'scheduler_new',
                  name: 'scheduler-new',
                  component: () =>
                    import(
                      /* webpackchunkname: "site" */ './views/sites/brand/overview/menuScheduler/index.vue'
                    ),
                },
                {
                  path: 'scheduler/:event_id',
                  name: 'scheduler-edit',
                  component: () =>
                    import(
                      /* webpackchunkname: "site" */ './views/sites/brand/overview/menuScheduler/index.vue'
                    ),
                },
              ],
            },
          ],
        },
      ],
    },
    {
      path: '/menu',
      component: () => import(/* webpackchunkname: "menu" */ './views/menu'),
      children: [
        {
          path: '',
          name: 'menu-sector-listing',
          meta: {
            roles: [UserRoles.admin],
          },
          component: () => import(/* webpackchunkname: "menu" */ './views/menu/sectors'),
        },
        {
          path: 'site/:site_id?',
          name: 'menu-site-listing',
          component: () => import(/* webpackchunkname: "menu" */ './views/menu/site-selection'),
        },
        {
          path: 'sector/:sector_id',
          component: () => import(/* webpackchunkname: "menu" */ './views/menu/sector'),
          children: [
            {
              path: '',
              name: 'menu-sector-info',
              meta: {
                roles: [UserRoles.admin],
              },
              component: () => import(/* webpackchunkname: "menu" */ './views/menu/sector/info'),
            },
            {
              path: 'brand/:company_id',
              name: 'brand',
              meta: {
                roles: [UserRoles.admin],
              },
              component: () => import(/* webpackchunkname: "menu" */ './views/menu/sector/brand'),
            },
            {
              path: 'brand/company/:company_id',
              name: 'menu-global',
              meta: {
                roles: [UserRoles.admin],
              },
              component: () => import(/* webpackchunkname: "menu" */ './views/menu/editor'),
            },
            {
              path: 'brand/company/:company_id/brands/:brand_id',
              name: 'menu-local',
              component: () => import(/* webpackchunkname: "menu" */ './views/menu/editor'),
            },
            {
              path: 'brand/company/:company_id/globalmods',
              meta: {
                roles: [UserRoles.admin],
              },
              name: 'global-mods',
              component: () => import(/* webpackchunkname: "menu" */ './views/menu/globalMods'),
            },
          ],
        },
      ],
    },
    {
      path: '/announcement',
      meta: {
        roles: [UserRoles.admin, UserRoles.site_operator, UserRoles.im_user],
      },
      component: () => import(/* webpackchunkname: "announcement" */ './views/announcement'),
      children: [
        {
          path: '',
          name: 'announcement-listing',
          meta: {
            roles: [UserRoles.admin, UserRoles.site_operator, UserRoles.im_user],
          },
          component: () =>
            import(/* webpackchunkname: "announcement" */ './views/announcement/listing'),
        },
        {
          path: ':announcement_id',
          name: 'announcement-settings',
          meta: {
            roles: [UserRoles.admin, UserRoles.site_operator, UserRoles.im_user],
          },
          component: () =>
            import(/* webpackchunkname: "announcement" */ './views/announcement/settings'),
        },
        {
          path: 'announcement-new',
          name: 'announcement-new',
          meta: {
            roles: [UserRoles.admin, UserRoles.site_operator, UserRoles.im_user],
          },
          component: () =>
            import(/* webpackchunkname: "announcement" */ './views/announcement/settings'),
        },
      ],
    },
    {
      path: '/reports',
      component: () => import(/* webpackchunkname: "reports" */ './views/reports'),
      children: [
        {
          path: '',
          name: 'report-multigroup',
          component: () => import(/* webpackchunkname: "reports" */ './views/reports/multigroup'),
        },
        {
          path: ':multigroup_id/sites',
          name: 'report-sites',
          component: () => import(/* webpackchunkname: "reports" */ './views/reports/sites'),
        },
        {
          path: ':multigroup_id/site/:site_id',
          name: 'report-site-details',
          component: () => import(/* webpackchunkname: "reports" */ './views/reports/site_details'),
          props: true,
        },
        {
          path: ':multigroup_id/site/:site_id/settings',
          name: 'report-site-settings',
          component: () =>
            import(/* webpackchunkname: "reports" */ './views/reports/site_settings'),
          props: true,
        },
      ],
    },
    {
      path: '/orders',
      component: () => import(/* webpackchunkname: "orders" */ './views/orders'),
      children: [
        {
          name: 'orders-sites',
          path: 'sites',
          component: () => import(/* webpackchunkname: "orders" */ './views/orders/sites'),
          props: true,
        },
        {
          name: 'orders-site',
          path: 'sites/:site_id?/:date?',
          component: () => import(/* webpackchunkname: "orders" */ './views/orders/sites'),
          props: true,
        },
        {
          name: 'orders-brand',
          path: 'sites/:site_id/brand/:brand_id/:date?',
          component: () => import(/* webpackchunkname: "orders" */ './views/orders/sites'),
          props: true,
        },
        {
          name: 'orders-multigroup',
          path: ':multigroup_id?',
          component: () => import(/* webpackchunkname: "orders" */ './views/orders/multigroup'),
          props: true,
        },
      ],
    },
    {
      path: '/sectors',
      component: () => import(/* webpackchunkname: "sectors" */ './views/sectors'),
      meta: {
        roles: [UserRoles.admin],
      },
      children: [
        {
          path: '',
          name: 'sectors',
          component: () => import(/* webpackchunkname: "sectors" */ './views/sectors/listing'),
        },
        {
          path: ':sector_id',
          name: 'sector_management',
          component: () => import(/* webpackchunkname: "sectors" */ './views/sectors/sector'),
        },
      ],
    },
    {
      path: '/users',
      meta: {
        roles: [UserRoles.admin, UserRoles.im_user],
      },
      component: () => import(/* webpackchunkname: "users" */ './views/users'),
      children: [
        {
          path: '',
          name: 'users-listing',
          component: () => import(/* webpackchunkname: "site" */ './views/users/listing'),
          meta: {
            roles: [UserRoles.admin, UserRoles.im_user],
          },
        },
        {
          path: 'user_new',
          name: 'user-new',
          component: () => import(/* webpackchunkname: "site" */ './views/users/settings'),
          meta: {
            roles: [UserRoles.admin, UserRoles.im_user],
          },
          beforeEnter: (to, from, next) => {
            if (!splitio?.state?.featureFlags['ap3-user-management-disable']) {
              next();
            } else {
              store.commit('adminPanel/setViewTitle', {
                depth: 0,
                title: 'Users',
                to: { name: 'users-listing' },
              });
              next({ name: 'users-listing' });
            }
          },
        },
        {
          path: ':user_id',
          name: 'user-settings',
          component: () => import(/* webpackchunkname: "site" */ './views/users/settings'),
          meta: {
            roles: [UserRoles.admin, UserRoles.im_user],
          },
          beforeEnter: (to, from, next) => {
            if (!splitio?.state?.featureFlags['ap3-user-management-disable']) {
              next();
            } else {
              store.commit('adminPanel/setViewTitle', {
                depth: 0,
                title: 'Users',
                to: { name: 'users-listing' },
              });
              next({ name: 'users-listing' });
            }
          },
        },
      ],
    },
    {
      path: '/promo',
      component: () => import(/* webpackChunkName: "promo" */ './views/promo'),
      children: [
        {
          path: '',
          name: 'promo',
          component: () => import(/* webpackchunkname: "promo" */ './views/promo/list'),
        },
        {
          path: 'edit/:promo_id',
          name: 'promo-edit',
          component: () => import(/* webpackchunkname: "promo" */ './views/promo/settings'),
        },
        {
          path: 'new',
          name: 'promo-new',
          component: () => import(/* webpackchunkname: "promo" */ './views/promo/settings'),
        },
      ],
    },
    {
      path: '/smart-analytics',
      component: () => import(/* webpackChunkName: "smart-analytics" */ './views/smart-analytics'),
      children: [
        {
          path: '',
          name: 'smart-analytics-dashboard',
          component: () =>
            import(/* webpackchunkname: "smart-analytics" */ './views/smart-analytics/dashboard'),
        },
        {
          path: 'add-tracker-tag',
          name: 'add-tracker-tag',
          component: () =>
            import(
              /* webpackchunkname: "smart-analytics" */ './views/smart-analytics/add-tracker-tag'
            ),
        },
      ],
    },
    {
      path: '/notification-manager',
      component: () =>
        import(/* webpackChunkName: "notification" */ './views/notification-manager'),
      children: [
        {
          path: '',
          name: 'notification-manager',
          component: () =>
            import(/* webpackchunkname: "notification" */ './views/notification-manager/list'),
        },
        {
          path: 'edit/:notification_id',
          name: 'notification-edit',
          component: () =>
            import(/* webpackchunkname: "notification" */ './views/notification-manager/edit'),
        },
        {
          path: 'new-notification',
          name: 'notification-new',
          component: () =>
            import(/* webpackchunkname: "notification" */ './views/notification-manager/new-notif'),
        },
      ],
    },
    {
      path: '/page-not-found',
      name: 'page-not-found',
      component: () => import(/* webpackChunkName: "pagenotfound" */ './views/pageNotFound'),
    },
    {
      path: '/*',
      redirect: '/page-not-found',
    },
  ],
});

router.beforeEach((to, from, next) => {
  // stop showing the spinner (loading) in case its on the page
  store.commit('adminPanel/setLoading', false);

  // check version requirement
  if (to.meta && to.meta.version) {
    if (semverCompare(process.env.VERSION, to.meta.version) >= 0) {
      return next();
    }
    return next({ name: 'page-not-found' });
  }

  // login has no auth requirements
  if (to.meta && to.meta.noAuth) {
    return next();
  }

  // all other pages require authentication
  if (store.getters['adminPanel/requireAuth']) {
    return next({
      name: 'login',
      query: {
        redirect: to.fullPath,
      },
    });
  }

  // check for permission meta requirement
  if (to.meta.roles) {
    if (store.getters['adminPanel/userHasAnyRole'](to.meta.roles)) {
      return next();
    }
    return next({ name: 'page-not-found' });
  }

  return next();
});

export default router;
