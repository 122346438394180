/* eslint-disable class-methods-use-this */
import Vue from 'vue';
import store from '@/store';
import { UserRoles } from '@/constants';
import { version } from '../../package.json';

let instance;

class HeapAnalytics {
  async init() {
    if (!window.heap.loaded) {
      window.heap.load(process.env.VUE_APP_HEAP_APP_ID);
    }

    const user = this.getUser;
    const userProperties = {
      version,
    };

    if (user) {
      if (user.email) {
        window.heap.identify(user.email);
      }
      if (user.name?.first && user.name?.last) {
        userProperties.fullName = `${user.name.first} ${user.name.last}`;
      }
      if (user.date?.created) {
        userProperties.creationDate = user.date.created;
      }
      const sites = await this._getSiteNames();
      userProperties.site = sites;
      userProperties.role = this.getRole;
    }

    window.heap.addUserProperties(userProperties);
  }

  async _getSiteNames() {
    const promises = [];
    const userSiteIds = this._getUserSiteIds();
    if (userSiteIds === 'All') return userSiteIds;
    userSiteIds.forEach(async (siteId) => {
      promises.push(Vue.prototype.api.get(`/location/group/${siteId}`));
    });
    const sitesData = await Promise.all(promises);
    return sitesData.map(({ data: userSite }) => userSite.name || 'None').join(',');
  }

  _getUserSiteIds() {
    if (this.isAdmin || this.hasRole(UserRoles.sys_admin)) return 'All';
    return this.getPermissions
      .filter((scope) => {
        return (
          scope.includes(UserRoles.site_operator) ||
          scope.includes(UserRoles.im_user) ||
          scope.includes(UserRoles.menu_user) ||
          scope.includes(UserRoles.dc_team) ||
          scope.includes(UserRoles.runner)
        );
      })
      .map((scopeId) => {
        return scopeId.split(':')[2];
      });
  }

  get getUser() {
    return store.getters['adminPanel/getUser'];
  }

  get hasRole() {
    return store.getters['adminPanel/hasRole'];
  }

  get getRole() {
    return store.getters['adminPanel/getRole'];
  }

  get getPermissions() {
    return store.getters['adminPanel/getPermissions'];
  }

  get isAdmin() {
    return store.getters['adminPanel/isAdmin'];
  }
}

// eslint-disable-next-line import/prefer-default-export
export function heap() {
  if (!instance) {
    instance = new HeapAnalytics();
  }
  return instance;
}
