import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBreadcrumbs } from 'vuetify/lib/components/VBreadcrumbs';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          class: _vm.dropDownItems && "title-brand clickable",
          on: { click: _vm.toggleSearch }
        },
        [
          _c("div", { staticClass: "H3-Primary-Left" }, [
            _vm._v(_vm._s(_vm.active_title))
          ]),
          _c("div", { staticClass: "brand-description" }, [
            _vm._v(_vm._s(this.description))
          ]),
          _vm.dropDownItems
            ? _c(VIcon, { staticClass: "chevron-down" }, [
                _vm._v("mdi-chevron-down")
              ])
            : _vm._e()
        ],
        1
      ),
      _vm.search && _vm.dropDownItems
        ? _c(
            VFlex,
            { staticClass: "search", attrs: { xs6: "" } },
            [
              _c(VAutocomplete, {
                attrs: {
                  items: _vm.dropDownItems,
                  value:
                    _vm.label !== "Search Sites"
                      ? _vm.activeItem
                      : _vm.activeSite,
                  "item-text": "name",
                  "item-value": "id",
                  "return-object": "",
                  "hide-no-data": "",
                  label: _vm.label,
                  "prepend-inner-icon": "mdi-magnify"
                },
                on: { change: _vm.onChange }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.search && _vm.secondaryItemsList && _vm.secondaryItemsList.length
        ? _c(
            VFlex,
            { staticClass: "search", attrs: { xs6: "" } },
            [
              _c(VAutocomplete, {
                attrs: {
                  items: _vm.secondaryItemsList,
                  value: _vm.secondaryActiveItem,
                  "item-text": "name",
                  "item-value": "id",
                  "return-object": "",
                  "hide-no-data": "",
                  label: _vm.secondaryItemLabel,
                  "prepend-inner-icon": "mdi-magnify"
                },
                on: {
                  change: function($event) {
                    return _vm.$emit("secondaryItemChanged", $event)
                  }
                }
              })
            ],
            1
          )
        : _vm._e(),
      _c("div", [
        _vm.address
          ? _c(
              "div",
              { staticClass: "timezone-address" },
              [
                _c(VImg, {
                  staticClass: "svg-logo",
                  attrs: { src: require("@/assets/location.svg") }
                }),
                _c("p", [
                  _c("strong", [_vm._v("Address:")]),
                  _vm._v("\n        " + _vm._s(_vm.address) + "\n      ")
                ])
              ],
              1
            )
          : _vm._e(),
        _vm.timezone
          ? _c(
              "div",
              { staticClass: "timezone-address" },
              [
                _c(VImg, {
                  staticClass: "svg-logo",
                  attrs: { src: require("@/assets/clock.svg") }
                }),
                _c("p", [
                  _c("strong", [_vm._v("Timezone:")]),
                  _vm._v("\n        " + _vm._s(_vm.timezone) + "\n      ")
                ])
              ],
              1
            )
          : _vm._e()
      ]),
      _vm.active_sub_title
        ? _c("div", [_vm._v(_vm._s(_vm.active_sub_title))])
        : _vm._e(),
      _vm.links.length > 1 && _vm.showBreadCrum
        ? [
            _c(VBreadcrumbs, {
              staticClass: "breadcrumbs",
              attrs: { items: _vm.links, divider: ">", large: "" }
            })
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }